<template>
  <v-app :style="colorVapp">
    <onebox_toolbar
      :quicksearch="true"
      :parentfolder="parentfolder"
      @inputdata="filteredItems"
      @loadfile="loadfolder()"
      @callparentfolder="sendParentfolder()"
      @callstorage="loadstorage()"
    ></onebox_toolbar>
    <v-content>
      <v-card class="elevation-0">
        <v-divider></v-divider>
        <v-list nav :color="color.BG">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">date_range</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage">ไฟล์อินบ๊อกซ์หมดอายุ</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <!-- <div id="demo"> -->
        <v-card class="elevation-0" :color="color.BG">
          <v-card-text class="pa-0">
            <v-container id="thiscontainer_trashinbox">
              <v-layout row wrap justify-center>
                <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex>
                <v-flex lg12 class="ma-4">
                  <v-layout row wrap class="pl-3 pr-3" v-if="resolutionScreen >= 500">
                    <v-flex lg4 offset-lg8 class="pl-3 pr-3 hidden-md-and-down">
                      <v-alert
                        dense
                        height="41"
                        v-model="clickSort"
                        :color="color.alertSort"
                        dark
                        v-show="clickSort"
                        style="margin-bottom: 0%;"
                        ><div>
                          <v-icon :color="color.alertText" v-if="multiSort === true"> mdi-chevron-up</v-icon>
                          <v-icon :color="color.alertText" v-else-if="multiSort === false"> mdi-chevron-down</v-icon>
                          <span :style="colorSort" v-if="sortby === 'name'">{{ $t("Filter.name") }}</span>
                          <span :style="colorSort" v-else-if="sortby === 'document_type'">{{ $t("Filter.doctype") }}</span>
                          <span :style="colorSort" v-else-if="sortby === 'sender_name'">{{ $t("Filter.sendname") }}</span>
                          <span :style="colorSort" v-else-if="sortby === 'expired_inbox'">{{ $t("Filter.expiredinbox") }}</span>
                          <v-btn
                            width="24"
                            height="24"
                            style="float: right;"
                            text
                            fab
                            x-small
                            :color="color.alertSort"
                            dark
                            @click="checkSort"
                          >
                            <v-icon :color="color.alertText"> mdi-close-circle</v-icon></v-btn
                          >
                        </div></v-alert
                      ></v-flex
                    >
                  </v-layout>

                  <v-layout v-if="resolutionScreen >= 500">
                    <v-flex xs4 lg4>
                      <v-flex lg5 xs5>
                        <v-select
                          prepend-inner-icon="sort"
                          hide-details
                          outlined
                          solo
                          dense
                          v-model="size"
                          :items="listsize"
                          class="text-center"
                          :item-color="color.theme"
                          :color="color.theme"
                          @change="clickpagination()"
                        >
                          <template v-slot:item="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                        </v-select>
                      </v-flex>
                    </v-flex>

                    <v-flex xs8 lg8 class="text-right">
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>folder</v-icon>
                        {{ countfolders }} {{ countfolder > 1 ? $t("folders") : $t("folder") }}
                      </v-chip>
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>mdi-file</v-icon>
                        {{ countfile }} {{ countfile > 1 ? $t("files") : $t("file") }}
                      </v-chip>
                    </v-flex>
                  </v-layout>
                  <!-- หน้าจอxs -->
                  <v-layout row wrap class="pl-3 pr-3" v-else>
                    <v-flex lg2 xs3 style="margin-left: 5px;">
                      <v-select
                        prepend-inner-icon="sort"
                        hide-details
                        outlined
                        solo
                        dense
                        v-model="size"
                        :items="listsize"
                        class="text-center "
                      >
                        <template v-slot:item="{ item }">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                        <template v-slot:selection="{ item }">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                      </v-select>
                    </v-flex>
                    <v-flex xs8 lg8 class="text-right" style="margin-left: 22px;">
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>folder</v-icon>
                        {{ countfolders }} {{ countfolder > 1 ? $t("folders") : $t("folder") }}
                      </v-chip>
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>mdi-file</v-icon>
                        {{ countfile }} {{ countfile > 1 ? $t("files") : $t("file") }}
                      </v-chip>
                    </v-flex>
                  </v-layout>
                  <br />
                  <div v-if="resolutionScreen <= 500">
                    <v-list two-line class="mb-6 pb-6 elevation-1" v-if="newrootfile.length !== 0">
                      <v-list-item v-for="item in newrootfile" :key="item.title" style="cursor: pointer;">
                        <v-list-item-avatar>
                          <v-badge color="orange" :icon="link" overlap v-if="item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P'">
                            <v-icon x-large :color="item.file_icon[1]" style="font-weight: 100">{{ item.file_icon[0] }}</v-icon>
                          </v-badge>
                          <div v-else>
                            <v-icon x-large :color="item.file_icon[1]" style="font-weight: 100">{{ item.file_icon[0] }}</v-icon>
                          </div>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title v-text="item.file_name"></v-list-item-title>
                          <v-list-item-subtitle v-text="formatdatetime(item.expired_inbox)"></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-menu>
                            <template v-slot:activator="{ on }">
                              <v-btn class="elevation-0" fab small v-on="on" outlined>
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="fn_openedit(), (newdata = item)">
                                <v-list-item-icon>
                                  <v-icon>
                                    mdi-folder-clock
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    แก้ไขวันหมดอายุ
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item @click="fn_opencancel(), (newdata = item)">
                                <v-list-item-icon>
                                  <v-icon>
                                    block
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    ยกเลิกวันหมดอายุ
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <v-list v-else class=" elevation-1">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div v-else>
                    <v-data-table
                      v-model="filemultipledelete"
                      :headers="headers"
                      :items="newrootfile"
                      :no-data-text="$t('tablefile.empty')"
                      :single-select="singleSelect"
                      :search="search"
                      item-key="file_id"
                      item-selected
                      :show-select="statusmutipledelete"
                      class="elevation-1"
                      :page.sync="page"
                      :items-per-page="size"
                      :hide-default-footer="true"
                      @page-count="pageCount = $event"
                    >
                      <template v-slot:[`header.file_type`]="{ header }">
                        <span :style="headerTable">{{ header.text }}</span>
                      </template>
                      <template v-slot:[`header.file_name`]="{ header }">
                        <span
                          class="pointer"
                          @click="(multiSort = !multiSort), (sortby = 'name'), (clickSort = true), sortFile(newrootfile)"
                          :style="headerTable"
                          >{{ $t(header.text) }}</span
                        >
                      </template>
                      <template v-slot:[`header.document_type`]="{ header }">
                        <span
                          class="pointer"
                          :style="headerTable"
                          @click="(multiSort = !multiSort),(sortby = 'document_type'), (clickSort = true), sortFile(newrootfile)"
                          >{{ $t(header.text) }}</span
                        >
                      </template>
                      <template v-slot:[`header.sender_name`]="{ header }">
                        <span
                          class="pointer"
                          :style="headerTable"
                          @click="(multiSort = !multiSort), (sortby = 'sender_name'),(clickSort = true), sortFile(newrootfile)"
                          >{{ $t(header.text) }}</span
                        >
                      </template>
                      <template v-slot:[`header.expired_inbox`]="{ header }">
                        <span
                          class="pointer"
                          :style="headerTable"
                          @click="(multiSort = !multiSort), (sortby = 'expired_inbox'),(clickSort = true), sortFile(newrootfile)"
                          >{{ $t(header.text) }}</span
                        >
                      </template>
                      <template v-slot:[`header.file_status`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                      </template>

                      <template v-slot:item="props">
                        <tr @contextmenu="test($event, props.item)" style="cursor: pointer">
                          <td class="text-center" v-show="statusmutipledelete">
                            <v-checkbox v-model="filemultipledelete" :value="props.item" />
                          </td>
                          <td width="7%" class="text-center">
                            <v-badge color="orange" overlap icon="link" v-if="props.item.file_status_sharelink === 'Y' || props.item.file_status_sharelink === 'P'">
                              <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                            </v-badge>
                            <v-icon v-else large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                          </td>
                          <td width="35%" :title="props.item.file_name">{{ props.item.file_name | subStr }}</td>
                          <td width="15%" :title="props.item.document_type">{{ props.item.document_type }}</td>
                          <td width="18%" :title="props.item.sender_name">{{ props.item.sender_name }}</td>
                          <td width="12%" :title="props.item.expired_inbox">{{ formatdatetime(props.item.expired_inbox) }}</td>
                          <td width="15%" class="text-center" v-if="props.item.file_status === 'M'"></td>
                          <td width="15%" v-else class="text-center">
                            <v-menu>
                              <template v-slot:activator="{ on }">
                                <v-btn class="elevation-0" fab small v-on="on" outlined>
                                  <v-icon>more_vert</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item @click="fn_openedit(), (newdata = props.item)">
                                  <v-list-item-icon>
                                    <v-icon>
                                      mdi-folder-clock
                                    </v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      แก้ไขวันหมดอายุ
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="fn_opencancel(), (newdata = props.item)">
                                  <v-list-item-icon>
                                    <v-icon>
                                      block
                                    </v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      ยกเลิกวันหมดอายุ
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </div>
                  <div class="text-xs-center">
                    <br />
                    <v-pagination
                      v-model="page"
                      :length="pageCount"
                      :color="color.theme"
                      :total-visible="10"
                      @input="clickpagination()"
                    ></v-pagination>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
            <trashforever
              :show="opendialog"
              @closedialog="(opendialog = false), (statusmutipledelete = false), clearmultipledelete()"
              @multidelete="(opendialog = false), (statusmutipledelete = false), multipledelete()"
              :file="filemultipledelete"
            >
            </trashforever>
            <trashinboxoutbox
              :show="opendialogdialog"
              @closedialog="opendialogdialog = false"
              :filedata="newdatafile"
              @loadfiles="loadfiles()"
            />
            <dialogedit
              :show="opendialogedit"
              :filedata="newdata"
              @closedialog="opendialogedit = false"
              @closedialogandrefresh="fn_get_file_inbox(), (opendialogedit = false)"
            />
            <dialogcancel
              :show="opendialogcancel"
              @closedialog="opendialogcancel = false"
              @closedialogandrefresh="fn_get_file_inbox(), (opendialogcancel = false)"
              :filedata="newdata"
            />
          </v-card-text>
        </v-card>
      </v-card>
    </v-content>
    <v-dialog v-model="msgConfirm" persistent max-width="500">
      <v-card>
        <v-card-title>
          <b>{{ $t("trashPage.messageboxheader") }}</b>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text><v-icon color="red">folder</v-icon>{{ $t("trashPage.messageboxsubheader") }}</v-card-text>
        <br />
        <v-card-text>
          <v-icon color="amber">error_outline</v-icon><b>{{ $t("trashPage.messageboxsubheader2") }}</b
          ><v-icon color="amber">error_outline</v-icon>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="msgConfirm = false">{{ $t("trashPage.canceldelete") }}</v-btn>
          <v-btn color="red  darken-1 white--text" depressed @click="callremove(datadelete)" :loading="processdelete">{{
            $t("trashPage.forcedelete")
          }}</v-btn>
        </v-card-actions>
        <v-progress-linear :active="processdelete" indeterminate color="green"></v-progress-linear>
      </v-card>
    </v-dialog>
    <div style="visibility:hidden;" id="dropzone">
      <div id="textnode">
        <v-icon size="200" dark>cloud_upload</v-icon>
        <h3>วางไฟล์เพื่อทำการอัปโหลด</h3>
      </div>
    </div>
  </v-app>
</template>

<script>
//Import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
//import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
// Import Component
const dialogcancel = () => import("../components/optional/dialog-cancel-expire-in-out");
const dialogedit = () => import("../components/optional/dialog-editexpire-in-out");
const trashinboxoutbox = () => import("../components/optional/dialog-trashinboxoutbox");
const trashforever = () => import("../components/optional/dialog-trashforever");
const uploadfolder = () => import("../components/upload/dialog-uploadfolder");
const uploadfiles = () => import("../components/upload/dialog-uploadfiles");
const currentfilerightclick = () => import("../components/contextmenu/currentfilerightclick");
const trashrightclick = () => import("../components/contextmenu/trashrightclick");
// const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");
import onebox_toolbar from "../components/layout/layout-toolbar";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {
    onebox_toolbar,
    VueFullScreenFileDrop,
    trashrightclick,
    uploadfiles,
    uploadfolder,
    trashforever,
    trashinboxoutbox,
    dialogedit,
    dialogcancel,
  },
  data: function() {
    return {
      newdata: [],
      opendialogcancel: false,
      opendialogedit: false,
      checkpagination: false,
      disablescrolling: true,
      newrootfile: [],
      newdatafile: [],
      opendialogdialog: false,
      opendialog: false,
      filemultipledelete: [],
      countfolder: "",
      countfile: "",
      file: [],
      folder: [],
      search: "",
      statusmutipledelete: false,
      page: 1,
      size: 20,
      listsize: [20, 50, 100, 300],
      processdelete: false,
      datadelete: {},
      msgConfirm: false,
      lists: [],
      pagination: {
        rowsPerPage: 100000,
        sortBy: "file_id",
        totalItems: 0,
        rowsPerPageItems: [1, 2, 4, 8],
      },
      loaddataprogress: true,
      parentfolder: "",
      loadingfile: false,
      openUploadfolder: false,
      openUploadfiles: false,
      showcurrentMenu: false,
      showMenu: false,
      currentfile: {
        file_icon: ["", ""],
        file_id: "",
        file_name: "",
        file_size: "",
        file_status: "",
        file_type: "",
      },
      x: 0,
      y: 0,
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: "#",
          align: "left",
          value: "file_type",
          width: "7%",
          sortable: false,
        },
        {
          text: "tablefile.filename",
          align: "left",
          value: "file_name",
          width: "20%",
          sortable: false,
        },
        {
          text: "tablefile.doctype",
          align: "left",
          value: "document_type",
          width: "15%",
          sortable: false,
        },
        {
          text: "tablefile.sendername",
          align: "left",
          value: "sender_name",
          width: "14%",
          sortable: false,
        },
        {
          text: "tablefile.expiredinbox",
          align: "left",
          value: "expired_inbox",
          width: "15%",
          sortable: false,
        },
        {
          text: "tablefile.option",
          value: "file_status",
          width: "15%",
          align: "left",
          sortable: false,
        },
      ],
      sortby: "",
      clickSort: false,
      multiSort: false,
      rootfile: [],
      folderSort: [],
      fileSort: [],
      sortdata: [],
      buff: [],
    };
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 30) return string.substring(0, 30) + "...";
        else return string;
      }
    },
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.countfile;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.newrootfile.slice(start, start + this.size);
    },
    countfolders() {
      let countfolder = this.folder.length;
      return countfolder;
    },
    countfiles() {
      let countfile = this.file.length;
      return countfile;
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
  },
  methods: {
    fn_openedit() {
      this.opendialogedit = true;
    },
    fn_opencancel() {
      this.opendialogcancel = true;
    },
    clickpagination() {
      if (this.checkpagination === true) {
        this.checksortorderby();
      } else {
        this.fn_get_file_inbox();
      }
    },
    // ใช้เรียกไฟล์ดึงข้อมูลออกมาแสดงผล
   async fn_get_file_inbox() {
      this.loaddataprogress = true;
      this.newrootfile = [];
      let offset = this.size * (this.page - 1);
      let payload = {
        limit: this.size.toString(),
        offset: offset.toString(),
        receiver_id: [this.dataAccountActive.business_info.business_id],
        receiver_type: "2",
        filename: "",
        fileextension: "",
        doc_no_start: "",
        doc_no_end: "",
        doc_date_start: "",
        doc_date_end: "",
        doc_type: "",
        cre_dtm_start: "",
        cre_dtm_end: "",
        sender_name: "",
        system: "",
        sellerTaxid: "",
        sellerBranchid: "",
        receiver_name: "",
        receiverTaxid: "",
        receiverBranchid: "",
        status: "",
        customer_box: "",
        expired_inbox: "Y",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(process.env.VUE_APP_SERVICE_GET_INBOX + "/api/v3/get_file_inbox", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
          if (response.data.status === "OK") {
            //  เชค response ว่า ไฟล์ที่ส่งมาเท่ากับ 0 หรือป่าว ถ้าใช่ก็ให้ปิด loaddataprogress และนำ countfile ไปแสดงผล
            if (response.data.result.data.length === 0) {
              this.loaddataprogress = false;
              this.countfile = response.data.result.total_file;
            } else {
              for (let i = 0; i < response.data.result.data.length; i++) {
                if (response.data.result.data[i]["filename"] !== null || response.data.result.data[i]["filename"] === "") {
                  let datafolder = {};
                  let typefile =
                    response.data.result.data[i]["filename"] === null || response.data.result.data[i]["filename"] === ""
                      ? [response.data.result.data[i]["filename"], ""]
                      : response.data.result.data[i]["filename"].split(".");
                  datafolder["file_name"] = response.data.result.data[i]["filename"];
                  datafolder["file_id"] = response.data.result.data[i]["file_id"];
                  datafolder["file_credtm"] = response.data.result.data[i]["cre_dtm"];
                  datafolder["document_date"] = response.data.result.data[i]["document_date"];
                  datafolder["document_type"] = response.data.result.data[i]["document_type"];
                  datafolder["expired_inbox"] = response.data.result.data[i]["expired_inbox"];
                  datafolder["inbox_id"] = response.data.result.data[i]["inbox_id"];
                  datafolder["sender_name"] = response.data.result.data[i]["sender_name"];
                  datafolder["expired_inbox_left"] = response.data.result.data[i]["expired_inbox_left"];
                  datafolder["status_share_link"] = response.data.result.data[i]["status_share_link"];
                  datafolder["user_id"] = response.data.result.data[i]["user_id"];
                  datafolder["permission_department_setting"] = response.data.result.data[i]["permission_department_setting"];
                  datafolder["permission_setting"] = response.data.result.data[i]["permission_setting"];
                  datafolder["permission_account"] = response.data.result.data[i]["permission_account"];
                  datafolder["file_type"] = typefile[typefile.length - 1];
                  datafolder["file_icon"] = this.seticon(typefile[typefile.length - 1]);
                  this.newrootfile.push(datafolder);
                  this.buff.push(datafolder);
                  this.file.push(datafolder);
                }
                this.loaddataprogress = false;
                this.countfile = response.data.result.total_file;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({ icon: "error", title: this.$t("toast.cannotconnectonebox") });
        });
    },
    dialogtrashinboxoutbox() {
      this.opendialogdialog = true;
    },
   async recovery(filerecover) {
      let payload;
      payload = {
        file_id: [filerecover.file_id],
        account_id: this.dataAccountId,
        status: "trash_inbox",
      };
      let auth = await gbfGenerate.generateToken();
     await this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/recover_inbox_outbox", payload,{headers: { Authorization: auth.code },}).then((response) => {
        console.log("res", response);
        if (response.data.status === "OK") {
          Toast.fire({
            icon: "success",
            title: "OK",
          });
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorMessage,
          });
        }
      });
      this.loadstorage();
      this.loadfiles();
    },
    opendialogtrue() {
      if (this.filemultipledelete.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        this.opendialog = true;
        console.log(this.filemultipledelete);
      }
    },
    clearmultipledelete() {
      this.filemultipledelete.splice(0, this.filemultipledelete.length);
    },
    checkSort() {
      // this.loadfolder();
      this.newrootfile = this.buff;
      this.clickSort = false;
      this.multiSort = false;
    },
    sortFile(parameter) {
      this.folderSort = [];
      this.fileSort = [];
      this.sortdata = [];
      this.newrootfile = [];
      let i, j;
      for (i = 0; i < parameter.length; i++) {
        if (parameter[i].file_type === "folder") {
          this.folderSort.push(parameter[i]);
        } else this.fileSort.push(parameter[i]);
      }
      if (this.sortby === "name") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.file_name.localeCompare(b.file_name);
          });
          this.fileSort.sort(function(a, b) {
            return a.file_name.localeCompare(b.file_name);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.file_name.localeCompare(a.file_name);
          });
          this.fileSort.sort(function(a, b) {
            return b.file_name.localeCompare(a.file_name);
          });
        }
      } else if (this.sortby === "document_type") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.document_type.localeCompare(b.document_type);
          });
          this.fileSort.sort(function(a, b) {
            return a.document_type.localeCompare(b.document_type);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.document_type.localeCompare(a.document_type);
          });
          this.fileSort.sort(function(a, b) {
            return b.document_type.localeCompare(a.document_type);
          });
        }
      } else if (this.sortby === "sender_name") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return b.sender_name - a.sender_name;
          });
          this.fileSort.sort(function(a, b) {
            return b.sender_name - a.sender_name;
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return a.sender_name - b.sender_name;
          });
          this.fileSort.sort(function(a, b) {
            return a.sender_name - b.sender_name;
          });
        }
      } else if (this.sortby === "expired_inbox") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.expired_inbox.localeCompare(b.expired_inbox);
          });
          this.folderSort.sort(function(a, b) {
            return a.expired_inbox.localeCompare(b.expired_inbox);
          });
          this.fileSort.sort(function(a, b) {
            return a.expired_inbox.localeCompare(b.expired_inbox);
          });
          this.fileSort.sort(function(a, b) {
            return a.expired_inbox.localeCompare(b.expired_inbox);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.expired_inbox.localeCompare(a.expired_inbox);
          });
          this.folderSort.sort(function(a, b) {
            return b.expired_inbox.localeCompare(a.expired_inbox);
          });
          this.fileSort.sort(function(a, b) {
            return b.expired_inbox.localeCompare(a.expired_inbox);
          });
          this.fileSort.sort(function(a, b) {
            return b.expired_inbox.localeCompare(a.expired_inbox);
          });
        }
      }
      this.sortdata.push(this.folderSort);
      this.sortdata.push(this.fileSort);
      for (i = 0; i < this.sortdata.length; i++) {
        for (j = 0; j < this.sortdata[i].length; j++) {
          this.newrootfile.push(this.sortdata[i][j]);
        }
      }
      return this.newrootfile;
    },
    filteredItems(search) {
      this.search = search;
    },
    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute + ":" + second;
      }
    },
    sendParentfolder() {
      if (this.$route.params.id === undefined) {
        this.parentfolder = this.account_active["directory_id"];
      } else {
        this.parentfolder = this.$route.params.id;
      }
    },
    async setPermissionFile(parameter, status) {
      console.log("parameter", parameter, status);
      let api;
      let payload;
      let msgres;
      if (parameter.account_sender === "") {
        if (parameter.file_type === "folder") {
          payload = {
            account_id: this.dataAccountId,
            folder_id: parameter.file_id,
            folder_name: "",
            status_folder: status,
            move_to_id: "",
            user_id: this.dataUsername,
          };
          api = "/api/v2/update_folder_id";
        } else {
          payload = {
            account_id: this.dataAccountId,
            user_id: this.dataUsername,
            file_id: parameter.file_id,
            filename: "",
            folder_id: "",
            status_file: status,
          };
          api = "/api/v2/update_file_id";
        }
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload,{headers: { Authorization: auth.code },})
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              if (status === "N") {
                msgres = "กู้คืน " + parameter.file_name + " สำเร็จ";
              } else {
                msgres = "ยกเลิกติดดาว " + parameter.file_name + " สำเร็จ";
              }

              Toast.fire({
                icon: "success",
                title: msgres,
              });
              // this.$emit('closecurrent')
              this.loadfolder();
              this.loadsharefolder();
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถติดดาว " + parameter.file_name + " ได้",
            });
          });
      } else {
        let api;
        let payload;
        let msgres;
        if (parameter.file_type === "folder") {
          payload = {
            id: parameter.file_id,
            account_id: this.dataAccountId,
            status_folder: status,
          };
          api = "/api/update_share_statusfolder";
        } else {
          payload = {
            id: parameter.file_id,
            account_id: this.dataAccountId,
            status_file: status,
          };
          api = "/api/update_share_statusfile";
        }
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload,{headers: { Authorization: auth.code },})
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              if (status === "N") {
                msgres = "กู้คืน " + parameter.file_name + " สำเร็จ";
              } else {
                msgres = "ยกเลิกติดดาว " + parameter.file_name + " สำเร็จ";
              }
              Toast.fire({
                icon: "success",
                title: msgres,
              });
              // this.$emit('closecurrent')
              this.loadfolder();
              this.loadsharefolder();
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถติดดาว " + parameter.file_name + " ได้",
            });
          });
      }
    },
    msgalert(file) {
      this.datadelete = file;
      this.msgConfirm = true;
    },
    callremove(file) {
      if (file["file_type"] === "folder") {
        this.deletefolder(file);
      } else {
        this.deletefile(file);
      }
    },
    async deletefile(file) {
      this.processdelete = true;
      let msg;
      // if(parameter.file_owner_eng === 'Me' && parameter.file_owner_th === 'ฉัน'){
      let payload = {
        account_id: this.dataAccountId,
        user_id: this.dataUsername,
        file_id: file["file_id"],
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_file", payload,{headers: { Authorization: auth.code },});
      try {
        console.log(response);
        this.processdelete = false;
        this.msgConfirm = false;
        if (response.data.status === "OK") {
          msg = "ลบ " + file.file_name + " ออกถาวรสำเร็จ";
          Toast.fire({
            icon: "success",
            title: msg,
          });
          this.loadstorage();
          this.loadfolder();
        } else {
          msg = "ลบ " + file.file_name + " ออกถาวรไม่สำเร็จ";
          Toast.fire({
            icon: "error",
            title: msg,
          });
        }
      } catch (ex) {
        console.log(ex);
        this.processdelete = false;
        this.msgConfirm = false;
        Toast.fire({
          icon: "error",
          title: ex,
        });
        // }
      }
    },
    async deletefolder(file) {
      this.processdelete = true;
      let msg;
      // if(parameter.file_owner_eng === 'Me' && parameter.file_owner_th === 'ฉัน'){
      let payload = {
        account_id: this.dataAccountId,
        user_id: this.dataUsername,
        folder_id: file["file_id"],
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(process.env.VUE_APP_SERVICE_DELETE_FOLDER + "/api/delete_folder", payload,{headers: { Authorization: auth.code },});
      try {
        console.log(response);
        this.processdelete = false;
        this.msgConfirm = false;
        if (response.data.status === "OK") {
          msg = "ลบ " + file.file_name + " ออกถาวรสำเร็จ";
          Toast.fire({
            icon: "success",
            title: msg,
          });
          this.loadfolder();
          this.loadstorage();
        } else {
          msg = "ลบ " + file.file_name + " ออกถาวรไม่สำเร็จ";
          Toast.fire({
            icon: "error",
            title: msg,
          });
        }
      } catch (ex) {
        console.log(ex);
        this.processdelete = false;
        this.msgConfirm = false;
        Toast.fire({
          icon: "error",
          title: ex,
        });
      }
      // }
    },

    test(e, filename) {
      console.log(e);
      e.preventDefault();
      this.showcurrentMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = false;
      this.$nextTick(() => {
        console.log(filename);
        this.currentfile = filename;
        this.showcurrentMenu = true;
      });
    },

    gotodirectory(folderid, type) {
      if (type === "folder") {
        this.$router.replace({ path: "/directory/" + folderid });
        this.loadfolder();
      }
    },

    show(e) {
      console.log(e);
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        if (this.showcurrentMenu === true) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
        }
      });
    },

    onDrop(files) {
      console.log(files);
    },
    seticon(parameter) {
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word-outline", "primary"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png") {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"){
      dataicon = ["mdi-file-video-outline", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    }
      else {
        dataicon = ["mdi-file-outline", "black"];
      }

      return dataicon;
    },

    calculatesize(_sizefilebyte) {
      let size;
      if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
        size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
      } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
        size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
      } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
        size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
      } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
        size = (_sizefilebyte / 1024).toFixed(2) + " KB";
      } else if (_sizefilebyte === "-") {
        size = _sizefilebyte;
      } else {
        size = _sizefilebyte + " B";
      }

      return size;
    },

    loadstorage() {
      this.$store.dispatch("check_storage", this.dataAccountId).then((res) => {});
    },
  },
  mounted() {
    var self = this;
    self.fn_get_file_inbox();
  },
  created() {},
};
</script>

<style>
#demo {
  width: 100%;
  height: 100%;
}

div#textnode {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  transition: font-size 175ms;
}

div#dropzone {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: visibility 175ms, opacity 175ms;
  display: table;
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  background: rgba(0, 0, 0, 0.45);
  font: bold 42px Oswald, DejaVu Sans, Tahoma, sans-serif;
}

#dropzone {
  min-height: 100%;
}

#thiscontainer_trashinbox {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.pointer {
  cursor: pointer;
}
</style>
